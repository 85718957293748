.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 264px;
  background-image: url("../images/footer-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 20px 0;
    padding: 0;
    li {
      font-size: 16px;
      font-weight: 400;
    }
    svg {
      min-width: 30px;
      min-height: 30px;
      fill: #212185;
    }
  }
  a {
    color: #212185;
    &:hover {
      color: #111111;
      svg {
        fill: #111111;
      }
    }
  }
}
