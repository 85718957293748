.section-technology {
  position: relative;
  .technology-pattern {
    position: absolute;
    z-index: -1;
    top: 0;
    left: -20%;
    transform: rotate(-38.952deg);
  }
}

.technology-item {
  position: relative;
  display: flex;
  z-index: 1;
  min-height: 380px;
  margin-top: 90px;
  padding: 90px 16px 40px 16px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.50);
  backdrop-filter: blur(15px);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  transition: all 300ms ease-in-out;
  @media screen and (min-width: 1024px) {
    padding: 90px 40px 40px 40px;
  }
  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(180deg, #845DF9 0%, #5F94FF 100%);
    &, .heading {
      color: #FFFFFF;
    }
  }
  > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
