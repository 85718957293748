.navbar {
  position: relative;
  z-index: 998;
  padding-top: .25rem;
  padding-bottom: .25rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(15px);

  > .container {
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      min-height: 3.25rem;
      width: 100%;
    }
  }
}

.navbar-brand .navbar-item {
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 575.9px) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &:first-child {
    @media (min-width: 1024px) {
      padding-left: 0;
    }
  }

  &:last-child {
    @media (min-width: 1024px) {
      padding-right: 0;
    }
  }

  .logo {
    width: 120px;
    @media (min-width: 576px) {
      width: 150px;
    }
    max-height: none;
    transition: transform .5s linear;
  }
}

.navbar-brand,
.navbar-tabs {
  align-items: center;
}

.navbar-burger {
  display: inline-block;
  color: #0C0C4C !important;
  @media (min-width: 1024px) {
    display: none;
  }

  svg {
    width: 20px;
  }
}

.navbar-nav {
  @media screen and (min-width: 576px) {
    display: flex;
    justify-content: space-around;
  }

  @media screen and (min-width: 1024px) {
    justify-content: end;
    margin-right: 0;
    width: 100%;
  }
}

.navbar-menu {
  display: none;
  transition: all .4s ease;

  &.is-active {
    display: block;
  }

  @media screen and (max-width: 1023px) {
    background: linear-gradient(140deg, #D4E3FF 0%, #F2E0FF 100%);
    backdrop-filter: blur(10px);
    padding: 10px 20px;
  }
  @media screen and (min-width: 1024px) {
    flex-grow: 1;
    flex-shrink: 0;
    display: block;
  }

}

a.navbar-item,
.navbar-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 0.6rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $main-text;
  cursor: pointer;
  z-index: 99;
  @media screen and (max-width: 1023px) {
    padding: 0.5rem 0.6rem;
  }

  @media (min-width: 1200px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &:focus,
  &:focus-within,
  &:hover,
  &.is-active {
    background: linear-gradient(140deg, #D4E3FF 0%, #F2E0FF 100%);
    color: #1664BF;
  }
}

.headroom--unpinned:not(.headroom--top),
.headroom--pinned:not(.headroom--top) {
  .navbar-brand .navbar-item .logo {
    width: 80px;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  min-height: 3.25rem;
  @media (max-width: 1023.9px) {
    width: 100%;
  }
}

.button-wrapper {
  .button {
    margin-right: 1rem;
  }
}
