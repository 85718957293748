.section-about {

}
.about-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background: rgba(255, 255, 255, 0.70);
  backdrop-filter: blur(20px);
  padding: 30px 20px;
  border-radius: 40px;
  border: 1px solid #8152F8;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 60px 40px;
  }
  @media screen and (min-width: 1024px) {
    gap: 60px;
  }
  @media screen and (min-width: 1400px) {
    gap: 100px;
  }
  .about-img {
    border-radius: 40px;
    max-width: 40%;
  }
  .about-pattern {
    display: none;
    position: absolute;
    top: -12%;
    right: 30px;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
}
