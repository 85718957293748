.section-project {

}
.project-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 130px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.70);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  @media screen and (min-width: 1024px) {
    min-height: 185px;
  }
  img {
    max-width: 260px;
  }
}
