.btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 24px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 40px;
  }
}

.button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 16px 12px;
  background: linear-gradient(90deg, #FF8278 0%, #FF8AD0 100%);
  color: #ffffff;
  font-family: 'MuseoModerno', cursive;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background: linear-gradient(90deg, #FF8AD0 0%, #FF8278 100%);
    color: #FFFFFF;
    svg {
      transform: translateX(4px);
    }
  }
  svg {
    margin-left: 8px;
    transition: 300ms;
  }
  .pattern-right,
  .pattern-left {
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 10px;
      border-style: solid;
      border-color: #FF827B;
      border-radius: 1px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
  .pattern-left {
    &:before {
      left: -4px;
      top: -4px;
      border-width: 2px 2px 0 0;
    }
    &:after {
      left: -4px;
      bottom: -4px;
      border-width: 0 2px 2px 0;
    }
  }
  .pattern-right {
    &:before {
      right: -4px;
      top: -4px;
      border-width: 2px 0 0 2px;
    }
    &:after {
      right: -4px;
      bottom: -4px;
      border-width: 0 0 2px 2px;
    }
  }

  &.button-secondary {
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
    &:hover {
      background: linear-gradient(90deg, #FF8AD0 0%, #FF8278 100%);
      color: #FFFFFF;
    }
  }
}
