.section-hero {
  position: relative;
  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }
  .hero-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 360px;
    background-image: url("../images/hero-img.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media screen and (min-width: 768px) {
      min-height: 480px;
    }
    @media screen and (min-width: 1024px) {
      min-height: 726px;
    }
  }
  .hero-content {
    text-align: center;
    max-width: 600px;
    @media screen and (min-width: 1024px) {
      max-width: none;
    }
  }

  .img-1,
  .img-2 {
    position: absolute;
    z-index: 99;
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
  .img-1 {
    top: -15%;
    right: -15%;
  }

  .img-2 {
    left: -8%;
    bottom: 5%;
  }

  h1 {
    text-transform: uppercase;
    font-size: 40px;
    @media screen and (min-width: 768px) {
      font-size: 60px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 100px;
    }
    color: $main-text;
  }
  p {
    font-size: 20px;
    font-weight: 500;
    color: $main-text;
  }
}
